<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="orders !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> سفارشات رایگان : </span>
              <span class="mx-1" >{{  purchasedByAdmin }}</span>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> سفارشات پرداختی : </span>
              <span class="mx-1" >{{  notPurchasedByAdmin }}</span>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نوع سفارش</label>
              <v-select
                  v-model="searchPurchasedByAdmin"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="searchPurchasedByAdminOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="4"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="orders"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >

          <!-- Column: Status -->
          <template #cell(isPayed)="data">
            <b-badge
                v-if="data.item.isPayed"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              پرداخت شده
            </b-badge>
            <b-badge
                v-else
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              پرداخت نشده
            </b-badge>
          </template>

          <!-- Column: sumPrice -->
          <template #cell(sumPrice)="data">
            <span>
               {{ makePrettyPrice(data.item.sumPrice) }} تومان
            </span>
          </template>

          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </span>
          </template>

          <!-- Column: isDelivered -->
          <template #cell(isDelivered)="data">
            <span v-if="data.item.isDelivered">تحویل داده شده</span>
            <span v-else>تحویل داده نشده</span>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <span v-if="data.item.status === 1">ثبت شده</span>
            <span v-else-if="data.item.status === 2">پرداخت شده</span>
            <span v-else-if="data.item.status === 3">ارسال شده</span>
            <span v-else-if="data.item.status === 4">تحویل داده شده</span>
            <span v-else-if="data.item.status === 5" class="text-danger">رد شده</span>

            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,1)">
                <span class="align-middle ml-50">ثبت شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,2)">
                <span class="align-middle ml-50">پرداخت شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,3)">
                <span class="align-middle ml-50">ارسال شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,4)">
                <span class="align-middle ml-50">تحویل داده شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,5)">
                <span class="align-middle ml-50">رد شده</span>
              </b-dropdown-item>


            </b-dropdown>
          </template>

          <template #cell(purchasedByAdmin)="data">
            <span v-if="data.item.purchasedByAdmin === null || data.item.purchasedByAdmin === false">پرداختی</span>
            <span v-else>رایگان</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(delete)="data">

            <b-link   v-b-modal.modal-delete @click="showModal(data.item.orderId)">
              <feather-icon icon="TrashIcon" class="text-danger" size="20"/>
            </b-link>

          </template>

          <template #cell(edit)="data">


            <b-link :to="{ name: 'apps-orders-info', params: { id: data.item.orderId } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteOrder(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {OrderGetAllRequest} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
import {OrderChangeStatusRequest} from "@/libs/Api/Order";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {OrderDeleteRequest} from "@/libs/Api/Order";

export default {
  name: "OrdersList",
  title:"لیست سفارشات - پنل ادمین",
  data(){
    return{
      orders:null,
      deleteItem:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      overlay:false,
      purchasedByAdmin:null,
      notPurchasedByAdmin:null,
      searchPurchasedByAdmin:'',
      searchPurchasedByAdminOptions:[
      {label:'همه',value:''},
      {label:'پولی',value:'true'},
      {label:'پرداختی',value:'false'},
    ],
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'orderId' ,label :'شناسه سفارش'},
        { key: 'isPayed' ,label :'وضعیت پرداخت'},
        // { key: 'description' ,label :'توضیحات'},
        { key: 'sumPrice' ,label :'مجموع'},
        { key: 'isDelivered',label: 'ترابری'},
        { key: 'createDate',label: 'تاریخ ثبت'},
        { key: 'status',label: 'وضعیت'},
        { key: 'purchasedByAdmin',label: 'پولی / پرداختی'},
        { key: 'delete',label:'حذف سفارش'},
        { key: 'edit',label:'نمایش سفارش'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getOrders(this.perPage,this.currentPage,this.searchQuery,this.searchPurchasedByAdmin)
  },
  methods:{
    async getOrders(count,pageNumber,search,purchasedByAdmin){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:pageNumber,
        count:count,
        search:search,
        purchasedByAdmin:purchasedByAdmin
      }

      let orderGetAllRequest = new OrderGetAllRequest(_this);
      orderGetAllRequest.setParams(data);
      await orderGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.orders = content.orders;
        _this.totalCounts = content.ordersCount;
        _this.notPurchasedByAdmin = content.notPurchasedByAdmin;
        _this.purchasedByAdmin = content.purchasedByAdmin;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async deleteOrder(param){
      let _this = this;
      _this.overlay = true;

      let orderDeleteRequest = new OrderDeleteRequest(_this);
      orderDeleteRequest.setId(param);
      await orderDeleteRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `سفارش حذف شد.`,
          },
        })
        _this.getOrders(_this.perPage,_this.currentPage,_this.searchQuery,_this.searchPurchasedByAdmin)
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async changeOrderStatus(orderId,status){
      let _this = this;
      _this.overlay = true;
      let data={
        orderId:orderId,
        status:status,
      }

      let orderChangeStatusRequest = new OrderChangeStatusRequest(_this);
      orderChangeStatusRequest.setId(data);
      await orderChangeStatusRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
        _this.getOrders(_this.perPage,_this.currentPage,_this.searchQuery,_this.searchPurchasedByAdmin)
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getOrders(nv,this.currentPage,this.searchQuery,this.searchPurchasedByAdmin);
    },
    currentPage:function (nv,ov) {
      this.getOrders(this.perPage,nv,this.searchQuery,this.searchPurchasedByAdmin);
    },
    searchQuery:function (nv,ov){
      this.getOrders(this.perPage,this.currentPage,nv,this.searchPurchasedByAdmin)
    },
    searchPurchasedByAdmin(nv,ov){
      console.log(nv)
      this.getOrders(this.perPage,this.currentPage,this.searchQuery,nv)
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
